import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { getToken, getRefreshToken, getProfile, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import publicApi from '@iso/lib/helpers/public_api';
import Notification from '@iso/components/Notification';
import cookies from '@iso/lib/helpers/cookies';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    try {
      const response = yield call(publicApi.login, payload);
      yield put({
        type: actions.LOGIN_SUCCESS,
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        profile: {
          id: response.user.id,
          role: response.user.role,
          email: response.user.email
        }
      });
      Notification('success', 'Welcome...!!!');
    } catch (error) {
      yield put({
        type: actions.LOGIN_ERROR
      });
      Notification('error', error);
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield cookies.set('access_token', payload.accessToken, { path: '/' });
    yield cookies.set('refresh_token', payload.refreshToken, { path: '/' });
    yield cookies.set('profile', payload.profile, { path: '/' });
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const accessToken = getToken();
    const refreshToken = getRefreshToken();
    const profile = getProfile();

    if (accessToken) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        accessToken,
        refreshToken,
        profile
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
