import config, { getCurrentLanguage } from './config';
import cookies from '@iso/lib/helpers/cookies';

import actions from './actions';

const lang = cookies.get('lang');
const initState = {
  isActivated: false,
  language: getCurrentLanguage(lang),
  languageList: config.options
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return {
        ...state,
        isActivated: !state.isActivated
      };
    case actions.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state;
  }
}
