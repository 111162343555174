import locale_en from './locales/en/default.json';
import locale_zh from './locales/zh/default.json';

const AppLocale = {
  en: {
    messages: {
      ...locale_en
    },
    locale: 'en'
  },
  zh: {
    messages: {
      ...locale_zh
    },
    locale: 'zh'
  }
};
export default AppLocale;
