const options = {
  admin: [
    {
      key: 'categories',
      label: 'sidebar.categories',
      leftIcon: 'ion-filing'
    },
    {
      key: 'articles',
      label: 'sidebar.articles',
      leftIcon: 'ion-ios-compose-outline'
    },
    {
      key: 'contact',
      label: 'sidebar.customer.message',
      leftIcon: 'ion-iphone'
    },
    {
      key: 'banner',
      label: 'sidebar.banner',
      leftIcon: 'ion-images'
    }
  ]
};

export default options;
