import actions from './actions';

const initState = { accessToken: null, refreshToken: null, profile: null, loading: false };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return { ...state, loading: true };
    case actions.LOGIN_SUCCESS:
      return {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        profile: action.profile,
        loading: false
      };
    case actions.LOGOUT:
    case actions.LOGIN_ERROR:
      return initState;
    default:
      return state;
  }
}
