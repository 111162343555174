import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
// import * as serviceWorker from './serviceWorker';

const rootNode = document.getElementById('root');
ReactDOM.render(<App />, rootNode);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: (registration) => {
//     console.log(registration);
//     const waitingServiceWorker = registration.waiting;

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', (event) => {
//         if (event.target.state === 'activated') {
//           window.location.reload();
//         }
//       });
//       waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//     }
//   }
// });
